<template>
  <div>
    <div class="row">
      <div class="col">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item"
            v-for="(t, key) in booking.items"
            :key="key"
          >
            <div class="row mb-2">
              <div class="col-2 my-auto">
                <img :src="t.treatment.image" :alt="t.title" width="100%" />
              </div>

              <div class="col my-auto">
                <h6 class="fw-bold mb-0">
                  {{ t.treatment.title }}
                </h6>
                <p class="mb-0">
                  <small>{{ t.treatment.description }}</small>
                </p>
              </div>

              <div class="col-auto">
                <h6 class="fw-bold text-success mb-0">
                  {{ t.treatment.duration }} min session
                </h6>
              </div>

              <div class="col-auto">
                <h6 class="fw-bold text-success mb-0">
                  {{ t.quantity }} booked
                </h6>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!--  -->

    <div class="row">
      <div class="col-auto my-auto">
        <div class="card border-0 bg-light shadow-none">
          <div class="card-body">
            <h4 class="fw-bold text-success mb-0">
              Total:
              <span v-if="booking.type == 'standard'">
                {{
                  booking.booking.retreat == "malta"
                    ? "€" + booking.total_euro.toFixed(2)
                    : "£" + booking.total.toFixed(2)
                }}
              </span>
              <span v-else>
                {{ booking.credits }} credit(s)
              </span>
            </h4>
          </div>
        </div>
      </div>
      <!-- <div class="col my-auto">
        <p class="mb-0">Booked: {{ booking.created_at | formatDate }}</p>
      </div> -->
      <!-- <div
              class="col-auto ms-auto my-auto"
              v-if="booking.booking.retreat == 'uk' && booking.status != 'paid'"
            >
              <router-link
                :to="'/treatments/' + booking.id + '/pay'"
                class="btn btn-success"
                ><i class="far fa-credit-card me-2"></i>Pay for
                Treaments</router-link
              >
            </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    booking: {
      type: Object
    }
  },
  filters: {
    formatDate: function(date) {
      return moment(date).format("LLL");
    },
    formatStatus: function(status) {
      if (status) {
        return status.replace("_", " ");
      }
      return "unknown";
    }
  }
};
</script>

<style>
</style>