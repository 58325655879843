<template>
  <div>
    <div class="container" v-if="booking">
      <div class="card border-0 shadow-none">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col">
              <h4 class="text-success fw-bold">
                Treatment Booking #{{ booking.id }}
              </h4>
            </div>
            <div class="col-auto ml-auto">
              <button
                v-if="booking.status !== 'paid' && !editing"
                class="btn btn-success btn-sm m-2"
                @click="editing = !editing"
              >
                <i class="far fa-edit me-2"></i
                >Edit
              </button>

              <button class="btn btn-sm btn-outline-success" @click="close">
                <i class="far fa-times"></i>
              </button>
            </div>
          </div>

          <!--  -->
          <edit-booking v-if="editing" :booking="booking"></edit-booking>
          <!--  -->
          <view-booking v-if="!editing" :booking="booking"></view-booking>
          <!--  -->
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import ViewBooking from "./view-partials/View";
import EditBooking from "./view-partials/Edit";

export default {
  data() {
    return {
      booking: null,
      editing: false
    };
  },
  methods: {
    fetchBooking() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/c/treatment-bookings/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.booking = data;
        });
    },
    close() {
      this.$router.push("/treatments");
    },
  },
  mounted() {
    this.fetchBooking();
  },
  components: {
    Loading,
    ViewBooking,
    EditBooking
  }
};
</script>

<style></style>
