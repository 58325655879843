<template>
  <div>
    <treatment-form :mode="'edit'" :booking="booking"></treatment-form>
  </div>
</template>

<script>
import TreatmentForm from "../../../components/treatments/Form";

export default {
  props: ["booking"],
  components: {
    TreatmentForm
  }
};
</script>

<style>
</style>